import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import { createGtag } from 'vue-gtag'

// Import Bootstrap CSS and JS
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'

// Import AOS
import AOS from 'aos'
import 'aos/dist/aos.css'

// Initialize AOS
AOS.init({
    once: false, // Allow animations to trigger multiple times
    mirror: true,
})

const app = createApp(App)

// Configure Google Analytics
const gtagOptions = {
  config: {
    id: 'G-YOUR_MEASUREMENT_ID', // Replace with your GA4 measurement ID
    params: {
      send_page_view: true
    }
  }
}

// Install the plugin
app.use(
  createGtag(gtagOptions)
)

app.use(router).use(i18n).mount('#app')
