<template>
  <div v-if="!hasConsent" class="cookie-consent">
    <div class="cookie-content">
      <p>{{ $t('cookies.message') }}</p>
      <div class="cookie-buttons">
        <button class="btn btn-primary btn-sm" @click="acceptCookies">{{ $t('cookies.accept') }}</button>
        <button class="btn btn-outline-light btn-sm" @click="declineCookies">{{ $t('cookies.decline') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { enableGA, disableGA } from '@/utils/analytics'

export default {
  name: 'CookieConsent',
  data() {
    return {
      hasConsent: false
    }
  },
  created() {
    // Check if user has already made a choice
    const consent = localStorage.getItem('cookieConsent')
    this.hasConsent = consent !== null
    if (consent === 'accepted') {
      enableGA()
    } else if (consent === 'declined') {
      disableGA()
    }
  },
  methods: {
    acceptCookies() {
      localStorage.setItem('cookieConsent', 'accepted')
      this.hasConsent = true
      enableGA()
    },
    declineCookies() {
      localStorage.setItem('cookieConsent', 'declined')
      this.hasConsent = true
      disableGA()
    }
  }
}
</script>

<style scoped>
.cookie-consent {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(33, 37, 41, 0.95);
  color: white;
  padding: 1rem;
  z-index: 1000;
}

.cookie-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.cookie-buttons {
  display: flex;
  gap: 0.5rem;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .cookie-content {
    flex-direction: column;
    text-align: center;
  }
  
  .cookie-buttons {
    margin-top: 1rem;
  }
}
</style> 