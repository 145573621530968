<template>
  <section id="services" class="py-5">
    <div class="container d-flex ">
      <div class="row">
        <div class=" col-lg-12 mx-auto  services-content  text-center" data-aos="fade-up">

          <h1 class="mb-4">{{ $t('services.title') }}</h1>
          <div class="services-intro mb-4">
            <p class="lead mb-4">
              {{ $t('services.intro') }}
            </p>
          </div>
          <div class="row">
            <div class="services-grid">
              <!-- <div class="service-item" v-for="service in services" :key="service.title" :data-aos="service.aos" :data-aos-delay="service.aosDelay">
            <h3>{{ service.title }}</h3>
            <p>{{ service.description }}</p>
          </div> -->
              <div class="service-item aos-item col-md-12" data-aos="fade-up">
                <h3>{{ $t('services.services.dotnet.title') }}</h3>
                <p>{{ $t('services.services.dotnet.description') }}</p>
              </div>
              <div class="service-item aos-item" data-aos="fade-up">
                <h3>{{ $t('services.services.oracleApex.title') }}</h3>
                <p>{{ $t('services.services.oracleApex.description') }}</p>
              </div>
              <div class="service-item aos-item col-md-12" data-aos="fade-up">
                <h3>{{ $t('services.services.oracleDb.title') }}</h3>
                <p>{{ $t('services.services.oracleDb.description') }}</p>
              </div>
              <div class="service-item aos-item col-md-12" data-aos="fade-up">
                <h3>{{ $t('services.services.sql.title') }}</h3>
                <p>{{ $t('services.services.sql.description') }}</p>
              </div>
              <div class="service-item aos-item col-md-12" data-aos="fade-up">
                <h3>{{ $t('services.services.dataProcessing.title') }}</h3>
                <p>{{ $t('services.services.dataProcessing.description') }}</p>
              </div>
              <div class="service-item aos-item col-md-12" data-aos="fade-up">
                <h3>{{ $t('services.services.serverMaintenance.title') }}</h3>
                <p>{{ $t('services.services.serverMaintenance.description') }}</p>
              </div>
              <div class="service-item aos-item col-md-12" data-aos="fade-up">
                <h3>{{ $t('services.services.processAutomation.title') }}</h3>
                <p>{{ $t('services.services.processAutomation.description') }}</p>
              </div>
              <div class="service-item aos-item col-md-12" data-aos="fade-up">
                <h3>{{ $t('services.services.processDigitization.title') }}</h3>
                <p>{{ $t('services.services.processDigitization.description') }}</p>
              </div>
              <div class="service-item aos-item col-md-12" data-aos="fade-up">
                <h3>{{ $t('services.services.guptaProgramming.title') }}</h3>
                <p>{{ $t('services.services.guptaProgramming.description') }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ServicesView',
  computed: {
    services() {
      return [
        {
          title: this.$t('services.services.dotnet.title'),
          description: this.$t('services.services.dotnet.description'),
          aos: 'fade-up',
          aosDelay: '100'
        },
        {
          title: this.$t('services.services.oracleApex.title'),
          description: this.$t('services.services.oracleApex.description'),
          aos: 'fade-up',
          aosDelay: '100'
        },
        {
          title: this.$t('services.services.oracleDb.title'),
          description: this.$t('services.services.oracleDb.description'),
          aos: 'fade-up',
          aosDelay: '100'
        },
        {
          title: this.$t('services.services.sql.title'),
          description: this.$t('services.services.sql.description'),
          aos: 'fade-up',
          aosDelay: '100'
        },
        {
          title: this.$t('services.services.dataProcessing.title'),
          description: this.$t('services.services.dataProcessing.description'),
          aos: 'fade-up',
          aosDelay: '100'
        },
        {
          title: this.$t('services.services.serverMaintenance.title'),
          description: this.$t('services.services.serverMaintenance.description'),
          aos: 'fade-up',
          aosDelay: '100'
        },
        {
          title: this.$t('services.services.processAutomation.title'),
          description: this.$t('services.services.processAutomation.description'),
          aos: 'fade-up',
          aosDelay: '100'
        },
        {
          title: this.$t('services.services.processDigitization.title'),
          description: this.$t('services.services.processDigitization.description'),
          aos: 'fade-up',
          aosDelay: '100'
        },
        {
          title: this.$t('services.services.guptaProgramming.title'),
          description: this.$t('services.services.guptaProgramming.description'),
          aos: 'fade-up',
          aosDelay: '100'
        }
      ]
    }
  }
}
</script>

<style scoped>
h1 {
  color: #fff;
}

.services-content {
  width: 100%;
  /* margin: 0 auto; */
  /* padding: 20px; */
  text-align-last: center;
}

.services-intro {
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 3rem;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  /* padding: 1rem; */
}

@media (max-width: 992px) {
  .services-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .services-grid {
    grid-template-columns: 1fr;
  }
}

.service-item {
  background: rgba(255, 255, 255, 0.1);
  padding: 1.5rem;
  border-radius: 8px;
}
.service-item h3 {
  color: #fff;
  margin-bottom: 1rem;
}

.service-item p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1rem;
  line-height: 1.6;
  margin: 0;
}

.lead {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0px !important;
}

.aos-item:hover {
  transform: none !important;
}
</style>
