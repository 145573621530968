<template>
  <section id="contact" class="py-5">
    <div class="container d-flex">
      <div class="contact-content text-center col-lg-12 mx-auto" data-aos="fade-up">
        <div class="row">
        <h1 class="mb-4">{{ $t('contact.title') }}</h1>
        <div class="mb-4">
          <p class="lead">{{ $t('contact.intro') }}</p>
        </div>
        <div class="contact-grid">
          <div class="contact-card" data-aos="fade-up" data-aos-delay="100">
            <h3>ARŪNAS STARINSKAS</h3>
            <p class="position">{{ $t('contact.people.ceo.position') }}</p>
            <div class="contact-info">
              <p><i class="fas fa-envelope"></i> <a href="mailto:arunas.starinskas@inerstartas.lt">arunas.starinskas@inerstartas.lt</a></p>
            </div>
          </div>
          <div class="contact-card" data-aos="fade-up" data-aos-delay="100">
            <h3>LUKAS ANUŽIS</h3>
            <p class="position">{{ $t('contact.people.cto.position') }}</p>
            <div class="contact-info">
              <p><i class="fas fa-envelope"></i> <a href="mailto:lukas.anuzis@interstartas.lt">lukas.anuzis@interstartas.lt</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactView'
}
</script> 

<style scoped>
.contact-content {
  width: 100%;
  margin: 0 auto;
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 1rem;
  width: 100%;
}

.contact-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  width: 100%;
}

.contact-card h3 {
  color: #fff;
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 0.5rem;

}

.position {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  font-weight: 300;
}

.contact-info {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  line-height: 1.8;
}

.contact-info p {
  margin: 0.5rem 0;
}

.contact-info a {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
  transition: color 0.3s ease;
}

.contact-info a:hover {
  color: #fff;
}


h1 {
  color: #fff;
  margin-top: -20px;
}

.lead {
  color: rgba(255, 255, 255, 0.9);
  margin-bottom: 0px !important;
}
.aos-item:hover {
  transform: none !important;
}

</style>
