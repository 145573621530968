<template>
  <section id="about" class="py-5">
    <div class="container d-flex">
      <div class="row">
        <div class="col-lg-12 mx-auto" data-aos="fade-up">
          <h1 class="text-center mb-4">{{ $t('about.title') }}</h1>
          <div class="about-content mb-5">
            <p class="lead mb-4">
              {{ $t('about.content1') }}
            </p>
            <p class="lead mb-4">
              {{ $t('about.content2') }}
            </p>
          </div>

          <h1 class="text-center mb-4">{{ $t('about.whyChooseUs') }}</h1>
          <div class="row">
            <div class="col-md-6 mb-4 feature-card" v-for="feature in content" :key="feature.id" data-aos="fade-up" data-aos-delay="100">
              <div class="card h-100">
                <div class="card-body">
                  <h5 class="card-title">{{ feature.title }}</h5>
                  <p class="card-text">{{ feature.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutView',
  computed: {
    content() {
      return [
        {
          id: 1,
          title: this.$t('about.features.flexibility.title'),
          description: this.$t('about.features.flexibility.description')
        },
        {
          id: 2,
          title: this.$t('about.features.experience.title'),
          description: this.$t('about.features.experience.description')
        },
        {
          id: 3,
          title: this.$t('about.features.efficiency.title'),
          description: this.$t('about.features.efficiency.description')
        },
        {
          id: 4,
          title: this.$t('about.features.security.title'),
          description: this.$t('about.features.security.description')
        }
      ]
    }
  }
}
</script>

<style scoped>
.about-content {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
  text-align: center;
}

h1,
h2 {
  color: #333;
}

h5 {
  font-weight: 500;
  font-size: 1.5rem;
}

.card {
  transition: transform 0.3s ease;
  border: none;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  border-radius: 80px;
}

.card:hover {
  transform: translateY(-5px);
}

.card-title {
  font-weight: 500;
  text-align: center;
}

.card-text {
  color: #666;
  text-align: center;
}

.card-body {
  text-align: center;
  background-color: #ddeaf5
}
</style>
