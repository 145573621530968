<template>
  <footer class="bg-dark text-light py-4 flex-grow-1">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h5>{{ $t('footer.companyName') }}</h5>
          <p class="mb-0">{{ $t('footer.companyCode') }}</p>
          <p class="mb-0">{{ $t('footer.address') }}</p>
          <p class="mb-0">{{ $t('footer.bankAccount') }}</p>
          <p class="mb-0">{{ $t('footer.bankCode') }}</p>
        </div>
      </div>
      <hr class="my-4">
      <div class="text-center">
        <p class="mb-0">{{ new Date().getFullYear() }} {{ $t('footer.companyName') }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>