<template>
  <div class="d-flex flex-column min-vh-100">
    <Navbar />
    <main class="flex-grow-1 mt-5 mt-xxl-0">
      <AboutView class="bg-about" />
      <ServicesView class="bg-services" />
      <ContactView class="bg-contact" />
    </main>
    <Footer />
    <CookieConsent />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import AboutView from '@/views/AboutView.vue'
import ServicesView from '@/views/ServicesView.vue'
import ContactView from '@/views/ContactView.vue'
import CookieConsent from '@/components/CookieConsent.vue'
import { initGA, trackPageView } from '@/utils/analytics'

export default {
  name: 'App',
  components: {
    Navbar,
    Footer,
    AboutView,
    ServicesView,
    ContactView,
    CookieConsent
  },
  mounted() {
    // Initialize Google Analytics
    initGA()
    
    // Track initial page view
    trackPageView(window.location.pathname)
    
    // Track page views on scroll
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      // Track when user reaches certain sections
      const sections = ['about', 'services', 'contact']
      sections.forEach(section => {
        const element = document.getElementById(section)
        if (element) {
          const rect = element.getBoundingClientRect()
          if (rect.top <= window.innerHeight * 0.5 && rect.bottom >= window.innerHeight * 0.5) {
            trackPageView(`/${section}`)
          }
        }
      })
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

section {
  scroll-margin-top: 76px;
  /* Height of the navbar */
  min-height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
}

.navbar {
  transition: background-color 0.3s ease;
  width: 100%;
}

.navbar.scrolled {
  background-color: rgba(33, 37, 41, 0.95) !important;
}

/* Hide scrollbar for Firefox */
html {
  scrollbar-width: none;
}

/* Hide scrollbar for IE and Edge */
body {
  -ms-overflow-style: none;
}

.bg-services {
  background-color: #1666a4;
}

.bg-about {
  background-color: #ddeaf5;
}

.bg-contact {
  background-color: #0a2e4d;
}

h1 {
  font-weight: 400;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

h2 {
  font-weight: 400;
  margin-bottom: 2rem;
  font-size: 2rem;
}

h3 {
  font-size: 1.3rem;
  font-weight: 500;
}

.flex-grow-1 {
  min-width: 385px;
  width: 100%;
}
</style>
