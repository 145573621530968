export default {
  nav: {
    about: 'Apie mus',
    services: 'Paslaugos',
    contact: 'Kontaktai'
  },
  cookies: {
    message: 'Mes naudojame slapukus, kad pagerintume jūsų naršymo patirtį ir analizuotume svetainės srautą. Tęsdami naudoti mūsų svetainę, sutinkate su slapukų naudojimu.',
    accept: 'Sutinku',
    decline: 'Atmesti'
  },
  about: {
    title: 'Apie mus',
    content1: 'UAB Interstartas yra Lietuvos IT įmonė, specializuojanti programinės įrangos kūrime, duomenų bazių valdyme ir verslo procesų automatizavime. Turėdami daugiametę patirtį šioje srityje, teikiame aukštos kokybės IT sprendimus įmonėms įvairiose srityse.',
    content2: 'Mūsų komandą sudaro patyrę profesionalai, kurie yra aistringi technologijoms ir pasišventę puikiems rezultatams. Glaudžiai bendradarbiaujame su klientais, kad suprastume jų poreikius ir sukurtume pritaikytus sprendimus, kurie skatina verslo augimą.',
    whyChooseUs: 'Kodėl verta rinktis mus',
    features: {
      flexibility: {
        title: 'Lankstumas ir prisitaikymas',
        description: 'Galime dirbti tiek kaip atskira komanda, tiek integruotis į jūsų projektą.'
      },
      experience: {
        title: 'Patirtis ir profesionalumas',
        description: 'Mūsų specialistai turi daugiametę patirtį įvairiose IT srityse.'
      },
      efficiency: {
        title: 'Efektyvumas', 
        description: 'Siūlome teisingus sprendimus, kurie atitinka jūsų verslo poreikius.'
      },
      security: {
        title: 'Saugumas ir patikimumas',
        description: 'Užtikriname aukščiausius duomenų apsaugos standartus.'
      }
    },
    callToAction: 'Susisiekite su mumis šiandien ir sužinokite, kaip galime padėti jūsų verslui augti.'
  },
  services: {
    title: 'Paslaugos',
    intro: 'Mūsų komanda yra pasiruošusi bendradarbiauti su įmonėmis, ieškančiomis naujų IT sprendimų ar norinčiomis išplėsti savo komandą nuomojantis aukštos kvalifikacijos specialistus. Mes siūlome šias paslaugas:',
    services: {
      dotnet: {
        title: '.NET programavimas ir priežiūra',
        description: 'Kuriame, vystome ir palaikome įvairias .NET programas, internetines svetaines ir paslaugas.'
      },
      oracleApex: {
        title: 'Oracle APEX programavimas',
        description: 'Kuriame ir pritaikome Oracle APEX sprendimus, padedančius optimizuoti verslo procesus.'
      },
      oracleDb: {
        title: 'Oracle duomenų bazių administravimas',
        description: 'Užtikriname duomenų bazių saugumą, stabilumą bei efektyvumą.'
      },
      sql: {
        title: 'SQL užklausų rašymas, PL/SQL programavimas',
        description: 'Kuriame sudėtingas užklausas, optimizuojame duomenų bazes.'
      },
      dataProcessing: {
        title: 'Duomenų apdorojimas',
        description: 'Analizuojame, transformuojame ir optimizuojame didelius duomenų srautus.'
      },
      serverMaintenance: {
        title: 'Serverių priežiūra',
        description: 'Administruojame serverius, užtikriname jų stabilumą ir saugumą.'
      },
      processAutomation: {
        title: 'Procesų automatizavimas',
        description: 'Kuriame sprendimus, kurie automatizuoja pasikartojančius uždavinius ir optimizuoja darbą.'
      },
      processDigitization: {
        title: 'Procesų skaitmeninimas',
        description: 'Padedame įmonėms pereiti prie skaitmeninių sprendimų, kurie palengvina ir pagreitina vidinę įmonės veiklą.'
      },
      guptaProgramming: { 
        title: 'Team Developer/Gupta programavimas',
        description: 'Kuriame, vystome ir palaikome įvairias programas su Gupta programavimo kalba.'
      }
    }
  },
  contact: {
    title: 'Kontaktai',
    intro: 'Jeigu turite projektų ar ieškote patikimų IT specialistų – susisiekite su mumis ir aptarsime bendradarbiavimo galimybes!',
    people: {
      ceo: {
        name: 'ARŪNAS STARINSKAS',
        position: 'DIREKTORIUS',
        email: 'arunas.starinskas@interstartas.lt',
        phone: '+370 685 49484'
      },
      cto: {
        name: 'LUKAS ANUŽIS',
        position: 'PROJEKTŲ VADOVAS',
        email: 'lukas.anuzis@interstartas.lt',
        phone: '+370 645 57933'
      }
    }
  },
  footer: {
    companyName: 'UAB Interstartas',
    companyCode: 'Įmonės kodas: 302672697',
    address: 'Adresas: Laurų g. 15, Avižienių km., Vilniaus raj., LT-14184',
    bankAccount: 'Banko sąskaita: Swedbank, AB, LT40 7300 0101 2865 2764',
    bankCode: 'Banko kodas: 73000'
  }
} 