export default {
  nav: {
    about: 'About Us',
    services: 'Our Services',
    contact: 'Contact Us'
  },
  cookies: {
    message: 'We use cookies to enhance your browsing experience and analyze site traffic. By continuing to use our site, you agree to our use of cookies.',
    accept: 'Accept',
    decline: 'Decline'
  },
  about: {
    title: 'About Us',
    content1: 'UAB Interstartas is a Lithuanian IT company specializing in software development, database management, and business process automation. With years of experience in the industry, we provide high-quality IT solutions to businesses across various sectors.',
    content2: 'Our team consists of experienced professionals who are passionate about technology and committed to delivering exceptional results. We work closely with our clients to understand their needs and develop tailored solutions that drive business growth.',
    whyChooseUs: 'Why Choose Us',
    features: {
      flexibility: {
        title: 'Flexibility',
        description: 'We adapt our solutions to meet your specific business needs and requirements.'
      },
      experience: {
        title: 'Experience',
        description: 'Our team brings years of industry expertise and technical knowledge to every project.'
      },
      efficiency: {
        title: 'Efficiency',
        description: 'We deliver optimized solutions that improve productivity and streamline operations.'
      },
      security: {
        title: 'Security',
        description: 'We prioritize data security and implement robust protection measures.'
      }
    },
    callToAction: 'Contact us today to discuss how we can help transform your business with our IT solutions.'
  },
  services: {
    title: 'Our Services',
    intro: 'Our team is ready to collaborate with companies looking for new IT solutions or wanting to expand their team by hiring highly qualified specialists. We offer the following services:',
    services: {
      dotnet: {
        title: '.NET Development and Maintenance',
        description: 'We create, develop, and maintain various .NET applications, websites, and services.'
      },
      oracleApex: {
        title: 'Oracle APEX Development',
        description: 'We create and implement Oracle APEX solutions that help optimize business processes.'
      },
      oracleDb: {
        title: 'Oracle Database Administration',
        description: 'We ensure database security, stability, and efficiency.'
      },
      sql: {
        title: 'SQL Query Writing, PL/SQL Programming',
        description: 'We create complex queries and optimize databases.'
      },
      dataProcessing: {
        title: 'Data Processing',
        description: 'We analyze, transform, and optimize large data streams.'
      },
      serverMaintenance: {
        title: 'Server Maintenance',
        description: 'We administer servers and ensure their stability and security.'
      },
      processAutomation: {
        title: 'Process Automation',
        description: 'We create solutions that automate repetitive tasks and optimize work.'
      },
      processDigitization: {
        title: 'Process Digitization',
        description: 'We help companies transition to digital solutions that simplify and speed up operations.'
      },
      guptaProgramming: {
        title: 'Team Developer/Gupta Development',
        description: 'We create, develop and maintain various applications using the Gupta programming language.'
      }
    }
  },
  contact: {
    title: 'Contact Us',
    intro: 'If you have projects or are looking for reliable IT specialists - contact us and we will discuss cooperation opportunities!',
    people: {
      ceo: {
        name: 'ARŪNAS STARINSKAS',
        position: 'DIRECTOR',
        email: 'arunas.starinskas@interstartas.lt',
        phone: '+370 685 49484'
      },
      cto: {
        name: 'LUKAS ANUŽIS',
        position: 'PROJECT MANAGER',
        email: 'lukas.anuzis@interstartas.lt',
        phone: '+370 645 57933'
      }
    },
    form: {
      name: 'Name',
      email: 'Email',
      message: 'Message',
      submit: 'Send Message'
    }
  },
  footer: {
    companyName: 'UAB Interstartas',
    companyCode: 'Company code: 302672697',
    address: 'Address: Laurų g. 15, Avižienių km., Vilniaus raj., LT-14184',
    bankAccount: 'Bank account: Swedbank, AB, LT40 7300 0101 2865 2764',
    bankCode: 'Bank code: 73000'
  }
} 