import { createI18n } from 'vue-i18n'
import en from './locales/en'
import lt from './locales/lt'

const messages = {
  en,
  lt
}

const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('language') || 'lt',
  fallbackLocale: 'en',
  messages
})

// Add language change handler
i18n.global.locale.value = localStorage.getItem('language') || 'lt'

export default i18n