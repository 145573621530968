// Google Analytics 4 Measurement ID
const GA_MEASUREMENT_ID = 'G-V2QV25PVK3' // Replace with your actual GA4 Measurement ID

// Initialize Google Analytics
export function initGA() {
  if (typeof window.gtag === 'undefined') {
    window.dataLayer = window.dataLayer || []
    window.gtag = function() {
      window.dataLayer.push(arguments)
    }
    window.gtag('js', new Date())
    window.gtag('config', GA_MEASUREMENT_ID, {
      page_path: window.location.pathname,
      send_page_view: false // We'll handle page views manually
    })
  }
}

// Track page views
export function trackPageView(path) {
  if (window.gtag) {
    window.gtag('event', 'page_view', {
      page_path: path
    })
  }
}

// Track custom events
export function trackEvent(eventName, eventParams = {}) {
  if (window.gtag) {
    window.gtag('event', eventName, eventParams)
  }
}

// Disable Google Analytics
export function disableGA() {
  if (window.gtag) {
    window.gtag('consent', 'update', {
      'analytics_storage': 'denied'
    })
  }
}

// Enable Google Analytics
export function enableGA() {
  if (window.gtag) {
    window.gtag('consent', 'update', {
      'analytics_storage': 'granted'
    })
  }
} 