<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
    <div class="container">
      <a class="navbar-brand" href="">
        <img src="@/assets/logo.png" alt="Interstartas Logo" class="d-inline-block align-text-top me-2 logo-image">
      </a>
      <div class="d-flex align-items-center">
        <div class="language-switcher me-2 d-lg-none">
          <button class="btn btn-outline-light btn-sm" @click="toggleLanguage">
            {{ currentLocale === 'lt' ? 'EN' : 'LT' }}
          </button>
        </div>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto">
          <li v-for="section in sections" :key="section.id" class="nav-item">
            <a class="nav-link" :class="{ active: currentSection === section.id }" :href="`#${section.id}`"
              @click.prevent="scrollToSection(section.id)">
              {{ $t(`nav.${section.id}`) }}
            </a>
          </li>
        </ul>
        <div class="d-flex language-switcher d-none d-lg-block">
          <button class="btn btn-outline-light btn-sm" @click="toggleLanguage">
            {{ currentLocale === 'lt' ? 'EN' : 'LT' }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { Collapse } from 'bootstrap'

export default {
  data() {
    return {
      currentSection: 'about',
      sections: [{ id: 'about', name: 'Apie mus' }, { id: 'services', name: 'Paslaugos' }, { id: 'contact', name: 'Kontaktai' }],
      navbarCollapse: null
    }
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    }
  },
  methods: {
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId);
      if (element) {
        const offset = 80; // Height of navbar
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.scrollY - offset + 1;

        // Close mobile menu if open
        if (this.navbarCollapse && this.navbarCollapse._isShown) {
          this.navbarCollapse.hide();
        }
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }


    },
    updateActiveSection() {
      let currentSection = this.sections[0].id;
      this.sections.forEach((section) => {
        const element = document.getElementById(section.id);
        if (element) {
          const scrollPosition = window.scrollY + 100;
          const elementTop = element.offsetTop;
          const elementBottom = elementTop + element.offsetHeight;
          if (scrollPosition >= elementTop && scrollPosition <= elementBottom) {
            currentSection = section.id;
          }
        }
      });
      this.currentSection = currentSection;
    },
    toggleLanguage() {
      const newLocale = this.currentLocale === 'lt' ? 'en' : 'lt';
      this.$i18n.locale = newLocale;
      if (localStorage.getItem('cookieConsent') === 'accepted') {
        localStorage.setItem('language', newLocale);
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.updateActiveSection);
    this.updateActiveSection();

    // Initialize Bootstrap Collapse
    const navbarCollapseElement = document.getElementById('navbarNav');
    if (navbarCollapseElement) {
      this.navbarCollapse = new Collapse(navbarCollapseElement, {
        toggle: false
      });
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.updateActiveSection);
    if (this.navbarCollapse) {
      this.navbarCollapse.dispose();
    }
  }
}
</script>

<style scoped>
.logo-image {
  height: 60px;
}

.navbar {
  max-height: 80px;
}

.navbar-collapse {
  background-color: #212529;
  padding: 0;
}

.language-switcher {
  display: flex;
  align-items: center;
}

.nav-link {
  position: relative;
  transition: color 0.3s ease;
  padding: 0.5rem 1rem;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  transition: width 0.3s ease;
}

.nav-link:hover::after,
.nav-link.active::after {
  width: 100%;
}

.nav-link.active {
  color: #fff !important;
}

@media (max-width: 912px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #212529;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .nav-link {
    padding: 0.75rem 1rem;
  }
}
</style>